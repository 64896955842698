import { styled } from '../styled';
import { ActionsStack } from '../ActionsStack';
import { Typography } from '../Typography';
import { OverflowTypography } from '../OverflowTypography';

export const CardWrapper = styled('article')<{ $isActionable?: boolean }>`
  display: grid;
  grid-template-areas: 'text action' 'content content';
  grid-template-columns: 1fr auto;
  place-content: center space-between;
  align-items: center;

  max-width: calc(100vw - ${({ theme }) => theme.spacing(8)});
  padding: ${({ theme, $isActionable }) =>
    theme.spacing($isActionable ? 2 : 0, 3)};

  border-radius: ${({ theme, $isActionable }) =>
    $isActionable ? theme.shape.small : ''};
  box-shadow: ${({ theme, $isActionable }) =>
    $isActionable ? theme.elevation[100] : ''};
`;

export const CardActions = styled(ActionsStack)`
  grid-area: action;

  color: ${({ theme }) => theme.palette.grey[700]};

  svg {
    width: 26px;
    height: 26px;
  }

  button {
    padding: ${({ theme }) => theme.spacing(0, 2)};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      padding: 0;
    }
  }
`;

export const CardTitle = styled(OverflowTypography)`
  font-size: 14px;
`;

export const CardContainer = styled.div`
  grid-area: content;

  text-align: left;
`;

export const CardText = styled.div`
  display: flex;
  grid-area: text;
  flex-direction: column;
  justify-content: center;

  max-width: calc(100vw - ${({ theme }) => theme.spacing(21)});
`;

export const CardDescription = styled(Typography)`
  word-break: break-word;
`;
