import { createPath as path } from '@common/shared';

const DIRECTORIES = 'directories';
const DOCUMENTS = 'documents';
const STAFF = 'staff';
const PERSONAL_AREA = 'personal-area';
const NOTIFICATIONS = 'notifications';
const CERTIFICATES = 'certificates';
const TEMPLATES = 'document-templates';
const RECRUITING = 'recruiting';
const FILL_RECRUITMENT_FORM = 'fill-recruitment-form';
const MY_FORMS = 'my-forms';
const CLARIFICATIONS = 'clarifications';
const CANDIDATE_FORMS = 'candidates-forms';
const IMPORTS = 'imports';
const VACATIONS = 'vacations';

export const appPaths = {
  documents: {
    base: path(DOCUMENTS),
    incoming: path(DOCUMENTS, 'incoming-documents'),
    outgoing: path(DOCUMENTS, 'outgoing-documents'),
    card: (id: string) => path(DOCUMENTS, id),
    members: (id: string) => path(DOCUMENTS, id, 'members'),
    additionalFiles: (id: string) => path(DOCUMENTS, id, 'additional-files'),
    referenceFiles: (id: string) => path(DOCUMENTS, id, 'reference-files'),
    history: (id: string) => path(DOCUMENTS, id, 'history'),
    linkedDocuments: (id: string) => path(DOCUMENTS, id, 'linked-documents'),
    comments: (documentId: string, requestId: string) =>
      path(DOCUMENTS, documentId, 'comments', requestId),
    add: path(DOCUMENTS, 'add'),
  },
  clarifications: {
    base: path(DOCUMENTS, CLARIFICATIONS),
    card: (documentId: string, requestId: string) =>
      path(DOCUMENTS, CLARIFICATIONS, documentId, requestId),
    documentReader: (documentId: string, requestId: string) =>
      path(DOCUMENTS, CLARIFICATIONS, documentId, requestId, 'document'),
    members: (documentId: string, requestId: string) =>
      path(DOCUMENTS, CLARIFICATIONS, documentId, requestId, 'members'),
    additionalFiles: (documentId: string, requestId: string) =>
      path(
        DOCUMENTS,
        CLARIFICATIONS,
        documentId,
        requestId,
        'additional-files',
      ),
    referenceFiles: (documentId: string, requestId: string) =>
      path(DOCUMENTS, CLARIFICATIONS, documentId, requestId, 'reference-files'),
    history: (documentId: string, requestId: string) =>
      path(DOCUMENTS, CLARIFICATIONS, documentId, requestId, 'history'),
    linkedDocuments: (documentId: string, requestId: string) =>
      path(
        DOCUMENTS,
        CLARIFICATIONS,
        documentId,
        requestId,
        'linked-documents',
      ),
  },
  directories: {
    base: path(DIRECTORIES),
    subdivisions: path(DIRECTORIES, 'subdivisions'),
    grants: path(DIRECTORIES, 'grants'),
    routes: path(DIRECTORIES, 'routes'),
    roles: path(DIRECTORIES, 'roles'),
    positions: path(DIRECTORIES, 'positions'),
    documentTypes: path(DIRECTORIES, 'document-types'),
    documentTemplates: path(DIRECTORIES, TEMPLATES),
  },
  personalArea: {
    base: path(PERSONAL_AREA),
    subdivisions: path(PERSONAL_AREA, 'subdivisions'),
    myForms: path(PERSONAL_AREA, MY_FORMS),
    notifications: path(PERSONAL_AREA, NOTIFICATIONS),
    onlyMyForms: path(MY_FORMS),
    certificates: path(PERSONAL_AREA, CERTIFICATES),
    integrationTokens: path(PERSONAL_AREA, 'integration-tokens'),
    powerOfAttorney: path(PERSONAL_AREA, 'power-of-attorney'),
    signatureConfirmation: path(PERSONAL_AREA, 'signature-confirmation'),
  },
  staff: {
    base: path(STAFF),
    sagas: path(STAFF, 'sagas'),
    imports: {
      base: path(STAFF, IMPORTS),
      dispatcherCard: (id: string) => path(STAFF, IMPORTS, id),
      sagaCard: (id: string) => path(STAFF, IMPORTS, 'saga', id),
    },
    card: (id: string) => path(STAFF, id),
    workplaces: (id: string) => path(STAFF, id, 'workplaces'),
    subdivisions: (id: string) => path(STAFF, id, 'subdivisions'),
  },
  documentTemplates: {
    base: path(DIRECTORIES, TEMPLATES),
    edit: (id: string) => path(DIRECTORIES, TEMPLATES, id),
    file: (id: string) => path(DIRECTORIES, TEMPLATES, id, 'file'),
    tags: (id: string) => path(DIRECTORIES, TEMPLATES, id, 'tags'),
  },
  recruiting: {
    base: path(RECRUITING),
    fill: FILL_RECRUITMENT_FORM,
    fillCard: (id: string) => path(FILL_RECRUITMENT_FORM, id),
    inviteLinks: path(RECRUITING, 'invite-links'),
    candidatesForms: {
      base: path(RECRUITING, CANDIDATE_FORMS),
      card: (id: string) => path(RECRUITING, CANDIDATE_FORMS, id),
    },
  },
  vacations: {
    base: path(VACATIONS),
    list: path(VACATIONS, 'list'),
    createPlanning: path(VACATIONS, 'create-planning'),
    editPlanning: (id: string) => path(VACATIONS, 'edit-planning', id),
    currentPlanning: path(VACATIONS, 'current-planning'),
    currentSchedule: path(VACATIONS, 'current-schedule'),
    planning: (id: string) => path(VACATIONS, id),
    edit: (id: string) => path(VACATIONS, id, 'edit'),
    vacation: (planningId: string, vacationId: string) =>
      path(VACATIONS, planningId, vacationId),
  },
};
